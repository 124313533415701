<template>
  <section class="benner">
    <h1>{{ $t('Head.Lecturer') }}</h1>
    <!-- 讲师专区 -->
    <p>{{ $t('teacher.banner_tip') }}</p>
    <!-- 企业内训讲师资源，可以快速了解企业师资力量 -->
  </section>

  <section class="filter-wrap" id="pageTop">
    <div class="content">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :dataSource="statusArr"
        @onChange="statusChange"
      >
        <template v-slot:right>
          <div class="search">
            <div class="search-icon" @click="search">
              <SearchOutlined style="color: #656565" />
            </div>
            <a-input
              class="search-input"
              v-model:value.trim="searchVal"
              :placeholder="$t('please_enter_keywords')"
              allow-clear
              @pressEnter="search"
            />
            <!-- 请输入关键字 -->
          </div>
        </template>
      </ListFilter>
    </div>
  </section>

  <section class="list">
    <div class="content">
      <a-spin :spinning="courseLoading">
        <template v-if="courseData.length">
          <div class="cards">
            <div class="item" v-for="item in courseData" :key="item.courseId">
              <div class="img">
                <img :src="item.coverImg" alt="cover" />
              </div>
              <div class="info">
                <div class="row">
                  <div class="title">{{ item.courseName }}</div>
                  <div class="status" v-if="currentTime > item.endTime">{{ $t('XB_HasEnded') }}</div>
                  <!-- 已结束 -->
                  <div class="status gray" v-else>{{ $t('not_over') }}</div>
                  <!-- 未结束 -->
                </div>
                <div class="row">
                  <div class="addr">
                    <EnvironmentOutlined style="font-size: 16px" />
                    <p>{{ item.address }}</p>
                  </div>
                  <div class="users">
                    <UserOutlined style="font-size: 14px" />
                    <p>{{ item.trainStudy ? item.trainStudy.length : 0 }} {{ $t('subject.people') }}</p>
                    <!-- 人 -->
                  </div>
                </div>
                <div class="row">
                  <div class="time">
                    <ClockCircleOutlined style="font-size: 15px" />
                    <p>{{ item.trainTime[0] }} ~ {{ item.trainTime[1] }}</p>
                  </div>
                  <router-link
                    class="link"
                    :to="{
                      path: '/teacher/offline/detail',
                      query: { id: item.taskId, did: item.detailId },
                    }"
                    >{{ $t('view_detail') }}</router-link
                  >
                  <!-- 查看详情 -->
                </div>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :defaultPageSize="20"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a-button v-if="type === 'prev'">{{ $t('pre') }}</a-button>
                <a-button v-else-if="type === 'next'">{{ $t('next') }}</a-button>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty v-else style="padding: 50px 0" />
      </a-spin>
    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref } from "vue";
import ListFilter from "@/components/filter/ListFilter.vue";
import { trainCourse } from "@/api/teacher";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    renderVNode,
  },
  setup() {
    const { t: $t } = useI18n();
    const currentTime = Date.parse(new Date()) / 1000;
    // 状态
    const statusArr = [
      {
        name: $t('XB_HasEnded'),
        // 已结束
        id: 1,
      },
      {
        name: $t('not_over'),
        // 未结束
        id: 2,
      },
    ];
    let statusActive = 0;
    const statusChange = (arr) => {
      statusActive = arr[0];
      getCourseList();
    };

    // 搜索
    const searchVal = ref(""),
      search = () => {
        currentPage.value = 1;
        getCourseList();
      };

    // 课程
    const courseData = ref([]),
      courseLoading = ref(true),
      currentPage = ref(1),
      pageTotal = ref(1),
      getCourseList = (scroll = false) => {
        courseLoading.value = true;
        courseData.value = [];
        trainCourse({
          page: currentPage.value,
          pageSize: 10,
          keyword: searchVal.value,
          timeStatus: statusActive,
        }).then((res) => {
          courseLoading.value = false;
          if (res.ret === 0) {
            pageTotal.value = res.data.totals;
            courseData.value = res.data.list || [];
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      pageChange = (n) => {
        currentPage.value = n;
        getCourseList(true);
      };
    getCourseList();

    return {
      currentTime,
      statusArr,
      statusChange,
      searchVal,
      search,
      getCourseList,
      courseData,
      courseLoading,
      currentPage,
      pageTotal,
      pageChange,
    };
  },
};
</script>

<style lang="less" scoped>
.benner {
  height: 324px;
  background-image: url("../../../assets/image/teacher_calendar_banner.png");
  background-position: center;
  background-repeat: repeat-y;
  .mixinFlex(center; center; column);
  h1 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 2px;
  }
  p {
    color: #fff;
    font-size: 26px;
    margin-bottom: 60px;
  }
}

.filter-wrap {
  background-color: #fff;
  margin-top: -60px;
  .content {
    .mixinWrap();
    .search {
      .mixinFlex(space-between; center);
      width: 220px;
      height: 32px;
      background-color: #fff;
      border-radius: 16px;
      overflow: hidden;
      border: 1px solid #d9d9d9;
      float: right;
      &-icon {
        width: 34px;
        height: 14px;
        .mixinFlex(center; center);
        border-right: 1px solid #bbbbbb;
        cursor: pointer;
      }
      ::v-deep(.ant-input-affix-wrapper-focused) {
        border-color: none;
        box-shadow: none;
      }
      &-input {
        border: none;
        width: calc(100% - 34px);
        height: 100%;
        background-color: #fff;
        padding: 0 8px;
        font-size: 14px;
        ::v-deep(.ant-input) {
          background-color: #fff;
        }
      }
    }
  }
}

.list {
  .content {
    .mixinWrap();
    padding: 22px 0 42px;
    .cards {
      padding-left: 54px;
      border-left: 1px solid #dddddd;
      position: relative;
      &::before {
        content: "";
        background-color: #dddddd;
        position: absolute;
        left: -1px;
        top: -22px;
        width: 1px;
        height: 22px;
      }
      &::after {
        content: "";
        background-color: @color-page-light;
        position: absolute;
        left: -1px;
        bottom: 0;
        width: 1px;
        height: 87px;
      }
      .item {
        height: 201px;
        background-color: #fff;
        margin-bottom: 20px;
        position: relative;
        &::before {
          content: "";
          width: 29px;
          height: 29px;
          background-color: #dddddd;
          border-radius: 50%;
          position: absolute;
          left: -69px;
          top: calc(50% - 15px);
        }
        .mixinFlex(space-between; center);
        .img {
          .mixinImgWrap(310px; 201px);
        }
        .info {
          width: calc(100% - 310px);
          height: 201px;
          padding: 30px;
          .row {
            .mixinFlex(space-between; center);
            .title {
              width: calc(100% - 100px);
              .mixinEllipsis(34px);
              color: #333;
              font-size: 24px;
            }
            .status {
              font-size: 18px;
              font-weight: 700;
              color: #666;
              &.gray {
                color: #999;
              }
            }
            .time,
            .users,
            .addr {
              .mixinFlex(flex-start; center);
              color: #999;
              p {
                font-size: 18px;
                margin: 0 0 0 6px;
                .mixinEllipsis(30px);
              }
            }
            .time,
            .addr {
              width: calc(100% - 100px);
            }
            .time {
              font-size: 20px;
            }
            .link {
              font-size: 18px;
              width: 120px;
              height: 33px;
              line-height: 31px;
              text-align: center;
              color: @color-theme;
              border: 1px solid @color-theme;
              border-radius: 4px;
            }
            &:first-child {
              margin-bottom: 12px;
            }
            &:last-child {
              margin-top: 32px;
            }
          }
        }
      }
    }
  }
}
</style>